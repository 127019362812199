:root {
  --border-color: #1b97d4;
  --text-primary: rgba(15, 135, 202, 1);
  --link-color: #304e9c;
  --professional-text-color: #9c0e0e;
  --green-color: #4a9034;
  --fiver-green-color: #1dbf73;
  --light-blue: #158fd0;
  --light-gray: #f5f5f5;
  --gray: #979797;

  --background-green: #4caf50;
  --background-orange: #ffa726;
  --background-blue: #0f87ca;

  --text-dark-blue: #170f49;
  --text-semi-black: #212121;

  --text-black: #000000;
  --text-light: #6f6c90;

  /* Button colors */
  --bs-btn-primary-bg: #0f87ca; /* Primary button background color */
  --bs-btn-primary-border: #0f87ca; /* Primary button border color */
  --bs-btn-primary-color: #ffffff; /* Primary button text color */
}

@import "@fontsource/poppins";
@import "@fontsource/poppins/100.css";
@import "@fontsource/poppins/200.css";
@import "@fontsource/poppins/300.css";
@import "@fontsource/poppins/400.css";
@import "@fontsource/poppins/500.css";
@import "@fontsource/poppins/600.css";
@import "@fontsource/poppins/700.css";
@import "@fontsource/poppins/800.css";
@import "@fontsource/poppins/900.css";
@import "@fontsource/poppins/400-italic.css";
@import "@fontsource/poppins/500-italic.css";

/* body {
  font-family: "Poppins", sans-serif !important;
} */

.mainBackground {
  height: 100%;
}

.mh-90 {
  min-height: 90vh;
  margin-bottom: 15px;
}

.mh-75 {
  min-height: 75vh;
  margin-bottom: 15px;
}

.mh-60 {
  min-height: 60vh;
  margin-bottom: 15px;
}

/* for professional info page */
.mh-63 {
  min-height: 62.1vh;
  margin-bottom: 15px;
}

.cursor-pointer:hover {
  cursor: pointer;
}

/* .traitList .btn.btn-secondary {
  border-color: var(--border-color);
  background: #45a2d5;
} */

.text-primary {
  color: var(--text-primary) !important;
}

.mainTextColor {
  color: var(--text-primary) !important;
}

.logoColor {
  color: var(--light-blue) !important;
}

.btn.btn-white {
  background-color: #e9e8e8;
  background: linear-gradient(0deg, #e9e8e8, #ffffff);
  border: 0;
  color: rgb(15, 135, 202) !important;
}

.btn.btn-white:hover,
.btn.btn-white:focus {
  background: linear-gradient(0deg, #f8f8f8 50%, #dfdfdf 98%);
  border: 0;
  color: rgb(15, 135, 202);
}

.z-50 {
  z-index: 50;
}

.accordion-button {
  border-bottom: 2px solid var(--border-color);
  color: var(--text-primary);
  font-weight: bold;
  padding: 8px;
  font-size: 1rem;
}

.accordion-button:focus {
  box-shadow: none;
  color: var(--text-primary);
  background-color: white;
}

.blueBar {
  background-color: var(--light-blue);
}

.blueBarText {
  font-size: 1.25rem;
}

@media only screen and (max-width: 767px) {
  .blueBarText {
    font-size: 0.7rem !important;
  }
}

.form-control:focus {
  color: none !important;
  background-color: none !important;
  border-color: none !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.heardBy .form-check-input {
  border-color: black;
}

.form-check-input[type="radio"]:checked {
  /* .custom-radio .form-check-input:checked { */
  background-color: rgb(11, 172, 11) !important;
  color: rgb(9, 185, 9);
}

.form-check-input[type="radio"]:focus {
  box-shadow: none;
}

/* remove arrow from input field number */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* input[type="number"] {
  -moz-appearance: textfield;
} */

.form-check-input[type="radio"] {
  font-size: 1.1rem;
  border: 1px solid rgb(161, 159, 159);
}

.faqs .accordion-item,
.advanceLearningList .accordion-item {
  border: none;
}

.faqs .accordion-button {
  background: #f9f9f9;
  padding: 15px;
  border: none;
  margin: 10px 0px;
  color: black !important;
  height: 65px;
}

.faqs .accordion-button:hover,
.advanceLearningList .accordion-button:hover {
  background: #f2f2f2;
}

.faqs .accordion-button::after,
.advanceLearningList .accordion-button::after {
  background-image: url("/plus_icon.png");
}

.faqs .accordion-button:not(.collapsed)::after,
.advanceLearningList .accordion-button:not(.collapsed)::after {
  background-image: url("/minus_icon.png");
}

.journalIconSize {
  font-size: 2rem;
}

.pointer {
  cursor: pointer;
}

.listGroup {
  max-height: 260px;
  overflow: auto;
  top: 45px;
}

.listGroupItem:hover {
  background-color: #94d8fa;
}

.form-check-input {
  margin-top: 0;
}

.mw-for-td {
  min-width: 85px;
}

/* offcavus css */
.offcanvas {
  padding: 30px 15px;
  padding-top: 0px;
  width: 23rem !important;
}

.offcanvas-body a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  padding-bottom: 15px;
}

.offcanvas-body a svg {
  color: rgb(94, 89, 89);
  font-size: 1.25rem;
}

.offcanvas-body a span {
  margin-left: 0.25rem;
  color: rgba(0, 0, 0, 0.5);
  font-size: 1rem;
  font-weight: 500;
}

.pointer {
  cursor: pointer;
}

.adminNav {
  background: var(--border-color);
}

.tableListBg {
  background: #fcfcfc;
}

.breadcrumb-item {
  font-size: 0.9rem;
}

.breadcrumb-item a {
  text-decoration: none;
  color: rgb(108, 117, 125);
}

th,
td {
  padding-top: 20px !important;
  padding-bottom: 15px !important;
}

.text-light-blue {
  color: var(--text-primary);
}

/* description and about cell width in listing of admin */
.descriptionCell {
  max-width: 30rem;
}

/* assessment page*/
.assessmentBtnHeader {
  font-size: 1.19rem;
}

.assessmentBtndes {
  font-size: 0.8rem;
}

/* truncate intro paragraph of pages */
.truncateWrapper {
  overflow: hidden;
  position: relative;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.truncate.expanded {
  white-space: normal;
}

.w-99 {
  display: inline-block;
  min-width: 99px;
}

/* advance */
.advanceLearning .accordion-item,
.advanceLearningList .accordion-item {
  border: none;
  background: transparent !important;
}

.advanceLearning .accordion-button {
  background: #f9f9f900;
  padding: 15px;
  border: none;
  margin: 10px 0px;
  color: black !important;
  height: 65px;
}

.advanceLearningList .accordion-button {
  background: #e7e7e78c;
  padding: 15px;
  border: none;
  margin: 5px 0px;
  color: black !important;
}

.advanceLearningList .accordion-button:hover,
.advanceLearningList .accordion-button:focus,
.advanceLearning .accordion-button:hover,
.accordion-button:focus {
  background: #e7e7e78c;
}

.advanceLearning .btn.btn-secondary {
  background: #bfbfbf8c;
  border: 0;
  color: black;
}

.advanceLearning .btn.btn-secondary:hover {
  background: #a8a8a88c;
  border: 0;
  color: black;
}

.progress-bar {
  background-color: #1e9bd7 !important;
}

.mytooltip > .tooltip-inner {
  max-width: max-content;
}

/* div .videoPlay {
  font-size: 15px;
  text-align: start;
  min-width: 220px;
  font-weight: bold;
  display: inline;
  border: 3px solid;
  border-radius: 50%;
  background: white;
  margin-right: 10px;
  padding: 5px 1px 6px 4px;
  color: red;
} */

/* .playIcon svg {
  font-size: 20px;
} */
