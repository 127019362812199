@import './src/app/variables';
$primary: #0f87ca;
$success: #4caf50;
$warning: #ffa726;
// $danger: #e53935;
$danger: #f44336;
$white: #fff;
$power-blue: #e0e8f0;

$background-primary: #4caf50;

$font-family: "Poppins", sans-serif;

@import "bootstrap/scss/bootstrap";

body {
  font-family: $font-family !important;
}

.btn-primary {
  color: $white;

  &:hover {
    color: $white;
    background-color: darken($primary, 10%);
    border-color: darken($primary, 10%);
  }

  &:active {
    color: $white;
    background-color: darken($primary, 10%);
    border-color: darken($primary, 10%);
  }

  &.disabled {
    color: $white;
    background-color: darken($primary, 10%);
    border-color: darken($primary, 10%);
  }

  &:disabled {
    color: $white;
    background-color: darken($primary, 10%);
    border-color: darken($primary, 10%);
  }
}

.btn-success {
  color: $white;

  &:hover {
    color: $white;
    background-color: darken($success, 10%);
    border-color: darken($success, 10%);
  }

  &:active {
    color: $white;
    background-color: darken($success, 10%);
    border-color: darken($success, 10%);
  }

  &:first-child:active {
    color: $white;
    background-color: darken($success, 10%);
    border-color: darken($success, 10%);
  }

  &:focus-visible {
    color: $white;
    background-color: darken($success, 10%);
    border-color: darken($success, 10%);
  }

  &.disabled {
    color: $white;
    background-color: darken($success, 10%);
    border-color: darken($success, 10%);
  }
}

.btn-warning {
  color: $white;

  &:hover {
    color: $white;
    background-color: darken($warning, 10%);
    border-color: darken($warning, 10%);
  }

  &:active {
    color: $white;
    background-color: darken($warning, 10%);
    border-color: darken($warning, 10%);
  }

  &.disabled {
    color: $white;
    background-color: darken($warning, 10%);
    border-color: darken($warning, 10%);
  }
}

.completed {
  background-color: $success;
  width: 18px;
  height: 18px;
}

.not-started {
  background-color: $primary;
  width: 18px;
  height: 18px;
}

.warning-circle {
  background-color: $warning;
  width: 18px;
  height: 18px;
}

.traitList {
  .btn.btn-secondary {
    border-color: $primary;
    background: $primary;
  }
}

.progress-bar {
  background-color: $primary !important;
}

.blueBar {
  background-color: $primary;
}

.shadow-md {
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.175);
}

.detailBox .form-check-input {
  border: 4px solid $warning;
}

.detailBox .form-check-input:checked {
  background-color: $success;
  border-color: $success;
  border: 2px solid $success;
}

.ql-editor {
  min-height: 150px;
}

/* Custom card & skelton */
.custom-cards {
  .list {
    .card {
      box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.175);
      transition: transform 0.3s;
      border-radius: 0.5rem;
      text-align: center;
      padding: 1rem 0rem 1rem 0rem;
      height: 100%;
      border: none;

      &:hover {
        transition: transform 0.3s;
        transform: scale(1.05);
      }

      .header {
        position: relative;
        display: flex;
        align-items: center;

        .border {
          position: absolute;
          left: 0%;
          top: 50%;
          transform: translateY(-50%);
          width: 100%;
          height: 2px;
          z-index: 1;
        }

        .icon {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          left: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-size: 1.9rem;
          z-index: 2;
          position: relative;
        }
      }

      .title {
        text-align: start;
        font-weight: bold;
        font-size: 1.25rem;
      }

      ul {
        padding-left: 0.25rem;
        text-align: left;

        li {
          list-style: none;

          &::before {
            content: "\25CB";
            margin-right: 0.5rem;
          }

          a {
            // line-height: 30px;
            line-height: 25px;
            font-size: 0.85rem;
            text-decoration: none;
          }

          /*  &:not(:last-child)::after {
            content: ","; 
            margin-right: 5px; 
            display: inline-block;
            color: inherit;
          } */
        }
      }
    }

    &:nth-child(3n + 1) {
      // 1st, 4th, 7th, etc.
      .card {
        .header {
          .border {
            background-color: lighten($bg-icon-purple, 33%);
          }
          .icon {
            background-color: $bg-icon-purple;
          }
        }
      }
    }

    &:nth-child(3n + 2) {
      // 2nd, 5th, 8th, etc.
      .card {
        .header {
          .border {
            background-color: lighten($bg-icon-steel-blue, 45%);
          }
          .icon {
            background-color: $bg-icon-steel-blue;
          }
        }
      }
    }

    &:nth-child(3n) {
      // 3rd, 6th, 9th, etc.
      .card {
        .header {
          .border {
            background-color: lighten($bg-icon-muted-cyan, 45%);
          }
          .icon {
            background-color: $bg-icon-muted-cyan;
          }
        }
      }
    }
  }
}

.custom-cards-skelton {
  .card {
    box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.175);
    transition: transform 0.3s;
    border-radius: 0.5rem;
    text-align: start;
    padding: 1rem 0rem 1rem 0rem;
    height: 100%;
    border: none;

    .header {
      position: relative;
      display: flex;
      align-items: center;

      .border {
        position: absolute;
        left: 0%;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: 2px;
        background-color: #efefef;
        z-index: 1;
      }

      .icon {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        left: 30px;
        display: flex;
        transform: translateY(-10%);
        justify-content: center;
        align-items: center;
        font-size: 1.9rem;
        z-index: 2;
        position: relative;
      }
    }

    .title {
      font-size: 1.25rem;
    }

    ul {
      padding-left: 0.25rem !important;
      text-align: left !important;
      li {
        a {
          line-height: 30px;
          font-size: 0.85rem;
          &:not(:last-child)::after {
            content: ",";
          }
        }

        &:not(:last-child)::after {
          content: ","; // Add a comma
          margin-right: 5px; // Add space after the text if needed
          display: inline-block; // Ensure it appears inline
          color: inherit;
        }
      }
    }
  }
}
/* End Custom card & skelton */

.bg-custom {
  // background-image: url("/background_even_page_BG.png");
  background-image: url("/background_even_page_BG.webp");
  background-repeat: no-repeat;
  background-size: cover; /* Ensures the background covers the entire element */
  background-position: center center; /* Centers the background image */
  height: 100%; /* Makes the background fill the full viewport height */
  width: 100%; /* Ensures it takes up the full width of the container */
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.bhdLno {
  width: 300px !important;
  height: 300px !important;
}

.custom-mh-100 {
  min-height: calc(100vh - 65px);
}
