.cookies-consent {
  position: fixed;
  bottom: -50px;

  width: 50%;
  background-color: var(--bs-dark);
  color: white;
  padding: 15px 20px;
  z-index: 1000;
  box-shadow: 0 -1px 10px rgba(0, 0, 0, 0.3);
  opacity: 0; /* Start invisible */
  transition: bottom 0.6s ease-in-out, opacity 0.6s ease-in-out; /* Animate both position and opacity */
}

.cookies-consent.visible {
  bottom: 15px; /* Move to the bottom of the viewport */
  opacity: 1;
}

.cookies-consent p {
  margin: 0;
  font-size: 0.875rem;
}

.cookies-consent .btn-primary {
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 0.875rem;
}
